<template>
  <div class="cart-calculator" style="margin: 4%">
    <h4 class="d-flex justify-content-between align-items-center mb-3">
      <span class="badge badge-primary badge-pill"
        >{{ $t('cart.calculator.title') }} {{ cartProducts.length }}</span
      >
    </h4>
    <ul class="list-group mb-3">
      <li
        class="list-group-item d-flex justify-content-between lh-condensed"
        v-for="(product, index) in cartProducts"
        :key="index"
      >
        <span class="text-muted" style="width:120px"
          >{{ product.productName }} {{ product.productPrice }}</span
        >
      </li>
      <hr />
      <li class="list-group-item d-flex justify-content-between">
        <span>{{ $t('cart.calculator.total') }}:</span>
        <strong>{{ totalValue.toFixed(2) }} Lei</strong>
      </li>
    </ul>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "cartCalculator",
  data() {
    return {
      totalValue: 0.0
    };
  },
  computed: mapState(["cartProducts"]),
  methods: {
    calulateTotalPrice() {
      this.totalValue = 0;
      this.cartProducts.forEach(product => {
        this.totalValue +=
          parseFloat(product.productPrice.replace(",", "")) * product.number;
      });
    }
  },
  created() {
    this.calulateTotalPrice();
  }
};
</script>
<style></style>
