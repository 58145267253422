<template>
<div>

  <v-layout row wrap justify-end="">
    <v-btn @click="goToShopHome" icon>
      <v-icon>fas fa-store</v-icon>
    </v-btn>
  </v-layout>

  <v-layout row wrap>
    <v-flex>
      <p>
        {{ $t('shop.category.table.text') }}
      </p>
    </v-flex>
  </v-layout>

    <v-layout row wrap>

      <v-flex md1>
        <p>Tables</p>
        <v-radio-group
            v-model="tableCategory"
            column
            @change="onChange"
        >
          <v-radio
              label="Dining table"
              color="black"
              value="dining"
          ></v-radio>
          <v-radio
              label="Coffee table"
              color="black"
              value="coffe"
          ></v-radio>
          <v-radio
              label="Bar table"
              color="black"
              value="bar"
          ></v-radio>
        </v-radio-group>
      </v-flex>

      <v-flex md11>
        <v-layout row wrap>
          <card-loader :loopCount="4" v-if="loading" />
          <v-flex
              d-flex md3 class="px-6"
              v-for="(item, index) in tableProducts"
              :key="index"
          >
            <card-template
                :item="item"
                @navigateProductDetail="productDetail"
                @navigateProductEdit="editProduct"
            />
          </v-flex>
        </v-layout>
      </v-flex>

    </v-layout>
</div>
</template>

<script>
import service from "../../../api/service";
import { errorToaster, successToaster } from "./shared/service/ErrorHandler";
import { chunkSubstr } from "../../../utils";
import CardLoader from "./shared/CardLoader";
import CardTemplate from "./shared/CardTemplate";

export default {
  name: "TableProducts",
  components: { CardLoader, CardTemplate },
  data() {
    return {
      tableProducts: [],
      backupAllProducts: [],
      loading: false,
      tableCategory:"",
    };
  },
  methods: {
    goToShopHome() {
      this.$emit("changeComponent", ["ShopHome", ""]);
    },
    onChange(){
      let productsByTableCategory = this.backupAllProducts.filter(value => {
        if(value.tableCategory == this.tableCategory){
          return value;
        }
      });
      this.tableProducts = productsByTableCategory;
    },
    getTableProducts() {
      this.loading = true;
      service
        .getTableProducts()
        .then(response => {
          this.loading = false;
          const data = response.data;
          let cloneData = [];
          data.forEach(item => {
            item.productDescription =
              chunkSubstr(item.productDescription, 100)[0] + "...";
            cloneData.push(item);
          });
          this.tableProducts = cloneData;
          this.backupAllProducts = this.tableProducts;
        })
        .catch(() => {
          errorToaster(
              this.$t('products.chopper.toaster.get.error.title'),
              this.$t('products.chopper.toaster.get.error.description')
          );
        });
    },
    //manadatory function's called from cardTemplate
    editProduct(product) {
      this.$emit("changeComponent", ["EditProduct", product]);
    },
    productDetail(productId) {
      this.$emit("changeComponent", ["ProductDetail", productId]);
    },
    deleteProduct(productId) {
      this.loadingDetail = true;
      service
        .deleteProduct(productId)
        .then(response => {
          this.loadingDetail = false;
          successToaster(this.$t('products.chopper.toaster.delete.success.title'), response.data);
        })
        .catch(error => {
          errorToaster(this.$t('products.chopper.toaster.delete.error.title'), error);
        });
    }
  },
  created() {
    this.getTableProducts();
  }
};
</script>

<style scoped></style>
