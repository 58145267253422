<template>
  <v-layout row wrap>
    <v-flex md3 class="px-2">
      <v-card class="mx-auto" max-width="344">
        <v-img @click="goToCreateProduct()"
          :src="require('../../../../assets/admin/settings.jpeg')"
          height="auto" class="hand"
        ></v-img>

        <v-card-title class="hand" @click="goToCreateProduct()">
          {{ $t('admin.createProduct') }}
        </v-card-title>

      </v-card>
    </v-flex>

    <v-flex md3 class="px-2">
      <v-card class="mx-auto" max-width="344">
        <v-img @click="goToHandlePicsum()"
               :src="require('../../../../assets/admin/picsum.webp')"
               height="auto" class="hand"
        ></v-img>

        <v-card-title class="hand" @click="goToHandlePicsum()">
          {{ $t('admin.handlePicsum') }}
        </v-card-title>

      </v-card>
    </v-flex>

  </v-layout>
</template>

<script>
import { mapMutations } from "vuex";

export default {
  name: "AdminPage",
  components: {},
  methods: {
    ...mapMutations(["setCategoryComponent"]),
    goToCreateProduct() {
      this.setCategoryComponent("CreateProductAdmin");
      this.$emit("changeComponent", ["CreateProductAdmin", ""]);
    },
    goToHandlePicsum() {
      this.setCategoryComponent("HandlePicsumHomePage");
      this.$emit("changeComponent", ["HandlePicsumHomePage", ""]);
    }
  }
};
</script>

<style scoped>
.hand{
  cursor: pointer;
}
</style>
