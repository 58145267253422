<template>
  <v-layout row wrap style="margin: 4%">
    <v-flex md3 v-for="loop in loopCount" v-bind:key="loop">
      <vcl-instagram
        :width="220"
        :height="450"
        style="padding: 20px"
      ></vcl-instagram>
    </v-flex>
  </v-layout>
</template>

<script>
import { VclInstagram } from "vue-content-loading";
export default {
  name: "CardLoader",
  props: ["loopCount"],
  components: { VclInstagram }
};
</script>

<style scoped></style>
