<template>
  <v-layout row wrap>
    <v-flex style="margin: 4%">
      <v-container grid-list-lg>
        <form id="productCU" @submit="actionProduct">
          <v-layout row wrap>

            <v-flex>
              <v-select
                  v-model="product.select"
                  :items="items"
                  label="Category"
                  item-value="value.category"
                  item-text="text"
                  @change="onChangeProductType($event)"
              ></v-select>
            </v-flex>

          </v-layout>

          <div v-if="showForm">
            <v-layout row wrap>
              <v-flex md6 for="productName" class="titleTop">Product Name</v-flex>
              <v-flex md6>
                <v-text-field
                  id="productName"
                  v-model="product.productName"
                  label="Enter Product Name"
                  class="form-control"
                />
              </v-flex>
            </v-layout>
            <v-layout row wrap>
              <v-flex md6 for="productCategory" class="titleTop"
                >Product Description</v-flex
              >
              <v-flex md6>
                <v-text-field
                  class="form-control"
                  id="productDescription"
                  name="productDescription"
                  v-model="product.productDescription"
                  label="Product description"
                />
              </v-flex>
            </v-layout>
            <v-layout row wrap>
              <v-flex md6 for="productCategory" class="titleTop"
                >Product Category</v-flex
              >
              <v-flex md6>
                <v-text-field
                  class="form-control"
                  id="productCategory"
                  name="productCategory"
                  v-model="product.productCategory"
                  label="Product category"
                />
              </v-flex>
            </v-layout>
            <v-layout row wrap>
              <v-flex md6 for="productCategory" class="titleTop"
                >Product Price</v-flex
              >
              <v-flex md6>
                <v-text-field
                  class="form-control"
                  id="productPrice"
                  name="productPrice"
                  v-model="product.productPrice"
                  label="Product price"
                />
              </v-flex>
            </v-layout>
            <v-layout row wrap>
              <v-flex md6 for="productName" class="titleTop"
                >Product Image</v-flex
              >
              <v-flex md6>
                <v-flex v-if="product.productImage">
                  <img
                    :src="product.productImage"
                    class="img-fluid rounded"
                    style="max-height: 700px; max-width: 127.135px;margin: auto"
                  />
                </v-flex>
                <v-file-input
                  label="Product Image"
                  v-model="product.productFile"
                  accept="image/*"
                  id="productFile"
                  name="productFile"
                  @click:clear="handleClear"
                  @change="handleFile"
                />
              </v-flex>
            </v-layout>

            <v-layout row wrap v-if="product.isChopperProduct">
              <v-flex md6>
                <v-select
                    v-model="product.size"
                    :items="sizes"
                    label="Size"
                    item-value="value.size"
                    item-text="text"
                ></v-select>
              </v-flex>
            </v-layout>

            <v-layout row wrap v-if="product.isTableProduct">
              <v-flex md6>
                <v-select
                    v-model="product.tableCategory"
                    :items="tables"
                    label="Table"
                    item-value="value.type"
                    item-text="text"
                ></v-select>
              </v-flex>
            </v-layout>

            <v-layout row wrap>
              <v-flex md6>
                <v-layout row wrap>
                  <v-flex md4 for="productRating" class="titleTop"
                    >Product Rating</v-flex
                  >
                  <v-flex md8>
                    <v-rating
                      v-model="product.productRating"
                      class="form-control"
                      :length="ratingLength"
                      color="yellow darken-3"
                      background-color="grey lighten-1"
                    ></v-rating>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
            <br />
            <v-btn class="white--text" color="#458ccc" type="submit">
              Save changes
            </v-btn>
          </div>
          <div v-else>
            Select category
          </div>
        </form>
      </v-container>
    </v-flex>
  </v-layout>
</template>

<script>
import { blobToBase64 } from "../../../../utils";
export default {
  name: "ProductFormAdmin",
  props: ["product"],
  data() {
    return {
      items: [
        { text: "Oil Product", value: { category: "oil" } },
        { text: "Chopper Product", value: { category: "chopper" } },
        { text: "Table Product", value: { category: "table" } },
      ],
      sizes: [
        { text: "Small", value: { size: "S" } },
        { text: "Medium", value: { size: "M" } },
        { text: "Large", value: { size: "L" } },
      ],
      tables: [
        { text: "Dining table", value: { type: "dining" } },
        { text: "Coffee table", value: { type: "coffe" } },
        { text: "Bar table", value: { type: "bar" } },
      ],
      errors: [],
      productCategory: "",
      productSeller: "",
      sellers: [],
      ratingLength: 5,
      showForm: false
    };
  },
  methods: {
    actionProduct(event) {
      event.preventDefault();

      this.product.productImage = this.product.productFile;

      console.log(this.product);

      this.$emit("submit-form", this.product);
    },
    handleFile(blob) {
      blobToBase64(blob).then(res => {
        this.product.productImage = res;
      });
    },
    handleClear() {
      this.product.productImage = "";
    },
    onChangeProductType(event) {
      if(event){
        this.showForm = true;
        if("oil" == event){
          this.setProductType(true, false, false);
        } else if("chopper" == event){
          this.setProductType(false, true, false);
        } else if ("table" == event){
          this.setProductType(false, false, true);
        }
      }
    },
    setProductType(isOil, isChopper, isTable){
      this.product.isOilProduct = isOil;
      this.product.isChopperProduct = isChopper;
      this.product.isTableProduct = isTable;
    }
  }
};
</script>

<style scoped>
.titleTop {
  margin-top: 3%;
}
</style>
