import api from "./api";

export default {
  login(user) {
    return api.post("/auth/login", user);
  },
  getBestProducts() {
    return api.get("/shop/best/products");
  },
  getOilsProducts() {
    return api.get("/shop/oil/products");
  },
  getChopperProducts() {
    return api.get("/shop/chopper/products");
  },
  getTableProducts() {
    return api.get("/shop/table/products");
  },
  deleteProduct(productId) {
    return api.delete(`/shop/products/${productId}`);
  },
  createAccount(user) {
    return api.post("/user/users", user);
  },
  getSimilarProduct(productCategory) {
    return api.get(`/shop/products/similarProduct/${productCategory}`);
  },
  getProductById(productId) {
    return api.get(`/shop/products/${productId}`);
  },
  setCheckoutProducts(request) {
    return api.post("/shop/checkout/products", request);
  },
  createProduct(formData, header) {
    return api.post("/shop/products", formData, header);
  },
  editProduct(productId, formData, header) {
    return api.put(`/shop/products/${productId}`, formData, header);
  },
  uploadPicsum(file, onUploadProgress) {
    let formData = new FormData();

    formData.append("file", file);

    return api.post("/upload/picsum", formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      },
      onUploadProgress
    });
  }
};
