<template>
  <div>
  <v-layout row wrap>
    <v-flex md4>
      <v-file-input
          accept=".jpg"
          label="Select Picsum"
          outlined
          dense
          @change="onFileChange"
      ></v-file-input>
    </v-flex>
    <v-flex md4>
      <v-btn
          class="ma-1"
          color="primary"
          plain
          @click="addPicsum"
      >
        Add Picsum
      </v-btn>
    </v-flex>
  </v-layout>
  <v-layout row wrap>
    <v-flex>
        <v-container fluid>
          <v-row>
            <v-col
                v-for="n in 6"
                :key="n"
                class="d-flex child-flex"
                cols="4"
            >
              <v-card class="mx-auto" >
                <v-img
                    id="miniPoza"
                    :src="require(`../../../../assets/picsum/${n}.jpg`)"
                    :lazy-src="require(`../../../../assets/picsum/${n}.jpg`)"
                    aspect-ratio="1"
                    class="grey lighten-2"
                >
                  <template v-slot:placeholder>
                    <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                    >
                      <v-progress-circular
                          indeterminate
                          color="grey lighten-5"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>

                <v-card-actions>
                  <v-spacer></v-spacer>

                  <v-btn
                      @click="changeImage(require(`../../../../assets/picsum/${n}.jpg`))"
                      class="ma-1"
                      color="grey"
                      plain
                  >
                    Change
                  </v-btn>

                  <v-btn
                      class="ma-1"
                      color="error"
                      plain
                  >
                    Delete
                  </v-btn>
                </v-card-actions>

              </v-card>
            </v-col>
          </v-row>
        </v-container>
    </v-flex>
  </v-layout>
  </div>
</template>

<script>
import { errorToaster } from "../shared/service/ErrorHandler";
// import service from "@/api/service";

export default {
  name: "HandlePicsumHomePage",
  data: () => ({
    file:undefined,
    src:undefined,
    progress: 0,
  }),
  components: {},
  methods: {
    onFileChange(file){
      this.file = file;
    },
    changeImage(src){
       this.src = src;
    },
    addPicsum(){
      if(this.file){

        // service.uploadPicsum(this.file, (event) => {
        //   this.progress = Math.round((100 * event.loaded) / event.total);
        // })
        //     .then(() => {
        //       successToaster("Uploaded", "File successfully uploaded!");
        //     })
        //     .catch(() => {
        //       this.progress = 0;
        //       this.file = undefined;
        //       errorToaster(
        //           "Bad Request",
        //           "Could not upload the file!"
        //       );
        //     });

      } else {
        errorToaster(
            "No file found",
            "Please select a file"
        );
      }
    },
  }
};
</script>
