<template>
  <v-layout row wrap>
    <v-flex>
      <v-card outlined>
        <v-footer dark padless>
          <v-card
            class="black lighten-1 white--text text-center"
            width="100%"
          >
            <v-card-text>
              <v-btn class="mx-4 white--text" icon>
                <v-icon
                  size="24px"
                  @click="goToPage(facebookUrl)"
                  class="fab fa-facebook"
                ></v-icon>
              </v-btn>
              <v-btn class="mx-4 white--text" icon>
                <v-icon
                  size="24px"
                  @click="goToPage(instagramUrl)"
                  class="fab fa-instagram"
                ></v-icon>
              </v-btn>
            </v-card-text>

            <v-card-text class="white--text pt-0">
              {{ $t('footer.description1') }}
              <br /><br />
              {{ $t('footer.description2') }}
            </v-card-text>

            <v-divider></v-divider>

            <v-card-text class="white--text">
              {{ new Date().getFullYear() }} — <strong>Ster</strong>
            </v-card-text>
          </v-card>
        </v-footer>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  name: "Footer",
  data: () => ({
    facebookUrl: "https://www.facebook.com/doitamplari",
    instagramUrl: "https://www.instagram.com/doi_tamplari/"
  }),
  methods: {
    goToPage(url) {
      window.open(url);
    }
  }
};
</script>

<style scoped></style>
