<template>
  <v-layout row wrap>
    <v-flex>
      <product-form-admin :product="product" v-on:submit-form="productAction" />
    </v-flex>
  </v-layout>
</template>
<script>
import service from "../../../../api/service";
import ProductFormAdmin from "./ProductFormAdmin";
import { errorToaster, successToaster } from "../shared/service/ErrorHandler";
export default {
  name: "createProduct",
  components: { ProductFormAdmin },
  data() {
    return {
      product: new Object()
    };
  },
  methods: {
    productAction: function(product) {
      let fd = new FormData();
      fd.append("product", JSON.stringify(product));
      fd.append("file", product.productImage);
      this.product = new Object();

      service
        .createProduct(fd, {
          header: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(() => {
          successToaster("Added Successfully", "Product Added Successfully");
        })
        .catch(error => {
          errorToaster("Cannot add product", error);
        });
    }
  }
};
</script>
<style lang="scss"></style>
