<template>
  <div>
    <v-row justify="end">
      <v-btn @click="goToComponentProducts" icon>
        <v-icon>fas fa-undo</v-icon>
      </v-btn>
      <v-btn @click="goToCategoryComponent" icon>
        <v-icon>fas fa-layer-group</v-icon>
      </v-btn>
    </v-row>
    <v-row justify="start">
      <v-card-title>
        <v-layout row wrap>
          <v-flex>
            <span class="headline">{{ product.productName }}</span>
          </v-flex>
        </v-layout>
      </v-card-title>
      <v-card-text>
        <div class="text-center" v-if="loadingDetail">
          <v-progress-linear indeterminate color="primary"></v-progress-linear>
        </div>
        <v-container grid-list-md v-else>
          <v-layout row wrap>
            <v-flex md2>
              <v-img
                id="miniPoza"
                @click="imageDialog = true"
                v-bind:src="product.productImage"
                v-bind:alt="product.productName"
                class="img-fluid rounded"
                style="max-height: 700px; max-width: 127.135px;margin: auto"
              />
            </v-flex>
          </v-layout>
          <v-layout row wrap>
            <v-flex md8>
              <v-layout row wrap>
                <v-flex>
                  <v-textarea
                    outlined
                    label="Product Description"
                    id="productDescription"
                    v-model="product.productDescription"
                    readonly
                  ></v-textarea>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex md4 style="padding: 1%">
              <v-layout row wrap>
                <v-flex md4 for="productRating">{{ $t('products.detail.review') }}</v-flex>
                <v-flex md8>
                  <v-rating
                    :size="15"
                    v-model="product.productRating"
                    :length="ratingLength"
                    color="yellow darken-3"
                    readonly
                    background-color="grey lighten-1"
                  ></v-rating>
                </v-flex>
              </v-layout>

              <v-layout row wrap>
                <v-flex>
                  <v-text-field
                    id="productCategory"
                    v-model="product.productCategory"
                    label="Product Category"
                    readonly
                  />
                </v-flex>
              </v-layout>

              <v-layout row wrap>
                <v-flex>
                  <v-text-field
                    id="productPrice"
                    v-model="product.productPrice"
                    label="Product Price"
                    readonly
                  />
                </v-flex>
              </v-layout>

              <v-layout row wrap>
                <v-flex>
                  <v-text-field
                    v-model="product.number"
                    single-line
                    type="number"
                    label="Product Pieces"
                  />
                </v-flex>
              </v-layout>

              <v-layout row wrap>
                <v-flex>
                  <v-btn @click="addToCart(product)" icon>
                    <v-icon>fas fa-shopping-cart</v-icon>
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
          <v-layout row wrap style="padding-top: 5%">
            <v-flex>
              <div class="text-center" v-if="loading">
                <v-progress-linear
                  indeterminate
                  color="primary"
                ></v-progress-linear>
              </div>

              <v-layout row wrap v-else>
                <v-expansion-panels :hover="true">
                  <v-expansion-panel>
                    <v-expansion-panel-header
                      >{{ $t('products.detail.titleSimilar') }}</v-expansion-panel-header
                    >
                    <v-expansion-panel-content>
                      <v-layout row wrap>
                        <v-flex
                          md3
                          v-for="(item, index) in similarProduct"
                          :key="index"
                        >
                          <card-template
                            :item="item"
                            @navigateProductDetail="productDetail"
                            @navigateProductEdit="editProduct"
                          />
                        </v-flex>
                      </v-layout>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-text>
    </v-row>
    <!-- Dialog for image -->
    <v-row justify="center">
      <v-dialog v-model="imageDialog" persistent max-width="290">
        <v-card>
          <v-card-title class="headline">{{
            product.productName
          }}</v-card-title>
          <v-card-text>
            <zoom-on-hover :img-normal="product.productImage"></zoom-on-hover>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="brown" text @click="imageDialog = false">{{ $t('button.close') }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import service from "../../../api/service";
import { mapMutations } from "vuex";
import { chunkSubstr } from "../../../utils";
import CardTemplate from "./shared/CardTemplate";
import {
  infoToaster,
  successToaster,
  errorToaster
} from "../../shop/products/shared/service/ErrorHandler.js";
import _ from "lodash";

export default {
  name: "productDetail",
  props: ["obj"],
  components: { CardTemplate },
  data() {
    return {
      imageDialog: false,
      product: new Object(),
      productId: "",
      prevComponent: "",
      loading: false,
      loadingDetail: false,
      similarProduct: [],
      ratingLength: 5
    };
  },
  computed: {
    getBreadCrumbComponents() {
      return this.$store.getters.getBreadCrumbComponents;
    },
    getCategoryComponent() {
      return this.$store.getters.getCategoryComponent;
    }
  },
  methods: {
    getSimilarProduct(productCategory) {
      this.loading = true;
      service
        .getSimilarProduct(productCategory)
        .then(response => {
          this.loading = false;
          const data = response.data;
          let cloneData = [];
          data.forEach(item => {
            item.productDescription =
              chunkSubstr(item.productDescription, 100)[0] + "...";
            cloneData.push(item);
          });
          this.similarProduct = cloneData;
        })
        .catch(error => {
          errorToaster(this.$t('products.detail.toaster.getSimilar.error.title'), error);
        });
    },
    getProductById(productId) {
      this.loadingDetail = true;
      service
        .getProductById(productId)
        .then(response => {
          this.loadingDetail = false;
          this.product = response.data;

          // Getting Similar Product
          this.getSimilarProduct(this.product.productCategory);
        })
        .catch(error => {
          errorToaster(this.$t('products.detail.toaster.get.error.title'), error);
        });
    },
    productDetail(productId) {
      this.$emit("changeComponent", ["ProductDetail", productId]);
    },
    editProduct(product) {
      this.$emit("changeComponent", ["EditProduct", product]);
    },
    goToComponentProducts() {
      this.removeLastBreadCrumbComponent();
      if (this.prevComponent == "ProductDetail") {
        this.$emit("changeComponent", ["ProductDetail", this.product._id]);
      } else if (this.prevComponent == "EditProduct") {
        this.$emit("changeComponent", ["EditProduct", this.product]);
      } else {
        this.$emit("changeComponent", [this.prevComponent, ""]);
      }
    },
    goToCategoryComponent() {
      this.$emit("changeComponent", [this.getCategoryComponent, ""]);
    },
    ...mapMutations([
      "ADD_CART_LOCAL",
      "removeLastBreadCrumbComponent",
      "forceRerender"
    ]),
    addToCart(product) {
      const data = _.find(this.$store.getters.cartProducts, product);
      if (data) {
        infoToaster(this.$t('cart.toaster.info.title'), this.$t('cart.toaster.info.description'));
      } else {
        successToaster(this.$t('cart.toaster.success.title'), this.$t('cart.toaster.success.description'));
        this.ADD_CART_LOCAL(product);
      }
    }
  },
  created() {
    this.forceRerender();

    this.product = new Object();
    this.similarProduct = [];
    this.productId = this.obj.id;

    this.getProductById(this.productId);

    const breadCrumbComponents = this.getBreadCrumbComponents.split("/");
    const lastBreadCrumbComponent =
      breadCrumbComponents[breadCrumbComponents.length - 1];
    this.prevComponent = lastBreadCrumbComponent;

    window.addEventListener("keydown", e => {
      if (e.key == "Escape") {
        this.goToCategoryComponent();
      }
    });
  }
};
</script>

<style>
#miniPoza:hover {
  cursor: pointer;
}
</style>
