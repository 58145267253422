<template>
  <v-layout row wrap>
    <v-flex>
      <v-layout row wrap>
        <template v-if="cartProducts.length > 0">
          <v-flex
            md4
            v-for="(item, index) in cartProducts"
            :key="index"
            style="margin: 4%"
          >
            <v-card class="d-inline-block mx-auto">
              <v-container>
                <v-row justify="space-between">
                  <v-col cols="auto">
                    <v-img
                      class="card-img-top mt-2"
                      v-bind:src="item.productImage"
                      style="max-height: 400px; max-width: 127.135px;margin: auto"
                    ></v-img>
                  </v-col>

                  <v-col cols="auto" class="text-center pl-0">
                    <v-row
                      class="flex-column ma-0 fill-height"
                      justify="center"
                    >
                      <v-col class="px-0">
                        <v-btn icon>
                          <v-icon @click="removeProductCart(item)"
                            >fa fa-trash</v-icon
                          >
                        </v-btn>
                      </v-col>

                      <v-col class="px-0">
                        <v-btn icon>
                          <v-icon @click="navigateToProductDetail(item)"
                            >fa fa-eye</v-icon
                          >
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-layout row wrap align-end style="margin: 0.5%">
                  <v-flex md4>
                    <h6 class="card-text">{{ item.productName }}.</h6>
                  </v-flex>
                  <v-flex md8>
                    <v-text-field
                      v-model="item.number"
                      style="width: 20%"
                      hide-details
                      single-line
                      type="number"
                      readonly
                    />
                  </v-flex>
                </v-layout>
              </v-container>
            </v-card>
          </v-flex>
        </template>
        <template v-else>
          <div class="error-template container">
            <h1>{{ $t('cart.error.title') }}</h1>
            <h2>{{ $t('cart.error.subtitle') }}</h2>
            <div class="error-details">{{ $t('cart.error.detail') }}</div>
          </div>
        </template>
      </v-layout>
    </v-flex>
    <v-flex md4>
      <cart-calculator ref="cartCalculator"></cart-calculator>
      <div v-if="this.loggedUser.firstName">
        <ul class="list-group mb-3">
          <checkout-modal></checkout-modal>
        </ul>
      </div>
      <div v-else>
        <div class="error-template container">
          <h2>{{ $t('cart.error.checkout') }}</h2>
          <v-btn><router-link to="/login">{{ $t('header.login') }}</router-link></v-btn>
        </div>
      </div>
    </v-flex>
  </v-layout>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import CartCalculator from "./CartCalculator";
import CheckoutModal from "./CheckoutModal";

export default {
  name: "CartProducts",
  components: { CartCalculator, CheckoutModal },
  data() {
    return {};
  },
  computed: mapState(["cartProducts", "loggedUser"]),
  methods: {
    /* Resetting the store and localstorage after removing product from cart */
    ...mapMutations(["SET_CART_PRODUCTS"]),

    removeProductCart(product) {
      const products = JSON.parse(localStorage.getItem("iki-cart"));
      for (let i = 0; i < products.length; i++) {
        if (products[i]._id === product._id) {
          products.splice(i, 1);
        }
      }
      this.SET_CART_PRODUCTS(products);
      localStorage.setItem("iki-cart", JSON.stringify(products));
      this.$refs.cartCalculator.calulateTotalPrice();
    },
    navigateToProductDetail(item) {
      this.$emit("changeComponent", ["ProductDetail", item._id]);
    }
  }
};
</script>

<style>
.error-template {
  padding: 40px 15px;
  text-align: center;
}
</style>
