<template>
  <div>
    <v-row justify="end">
      <v-btn @click="goToComponentProducts" icon>
        <v-icon>fas fa-undo</v-icon>
      </v-btn>
      <v-btn @click="goToCategoryComponent" icon>
        <v-icon>fas fa-layer-group</v-icon>
      </v-btn>
    </v-row>
    <v-row justify="start">
      <v-card-title>
        <v-layout row wrap>
          <v-flex>
            <span class="headline">Edit Product</span>
          </v-flex>
        </v-layout>
      </v-card-title>
      <v-card-text>
        <v-container>
          <product-form-admin
            :product="product"
            v-on:submit-form="productAction"
          />
        </v-container>
      </v-card-text>
    </v-row>
  </div>
</template>

<script>
import service from "../../../../api/service";
import { successToaster, errorToaster } from "../shared/service/ErrorHandler";
import ProductFormAdmin from "./ProductFormAdmin";
import { mapMutations } from "vuex";
export default {
  name: "EditProduct",
  props: ["obj"],
  components: { ProductFormAdmin },
  computed: {
    getBreadCrumbComponents() {
      return this.$store.getters.getBreadCrumbComponents;
    },
    getCategoryComponent() {
      return this.$store.getters.getCategoryComponent;
    }
  },
  data() {
    return {
      product: new Object(),
      prevComponent: ""
    };
  },
  methods: {
    ...mapMutations(["removeLastBreadCrumbComponent"]),
    productAction: function(product) {
      // Update the product to server
      let fd = new FormData();
      fd.append("product", JSON.stringify(product));
      fd.append("file", product.productImage);
      this.product = new Object();

      service
        .editProduct(product._id, fd, {
          header: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(() => {
          successToaster("Added Successfully", "Product Added Successfully");
        })
        .catch(error => {
          errorToaster("Cannot add product", error);
        });
    },
    goToComponentProducts() {
      this.removeLastBreadCrumbComponent();
      if (this.prevComponent == "ProductDetail") {
        this.$emit("changeComponent", ["ProductDetail", this.product._id]);
      } else if (this.prevComponent == "EditProduct") {
        this.$emit("changeComponent", ["EditProduct", this.product]);
      } else {
        this.$emit("changeComponent", [this.prevComponent, ""]);
      }
    },
    goToCategoryComponent() {
      this.$emit("changeComponent", [this.getCategoryComponent, ""]);
    }
  },
  created() {
    this.product = this.obj;

    const breadCrumbComponents = this.getBreadCrumbComponents.split("/");
    const lastBreadCrumbComponent =
      breadCrumbComponents[breadCrumbComponents.length - 1];
    this.prevComponent = lastBreadCrumbComponent;

    window.addEventListener("keydown", e => {
      if (e.key == "Escape") {
        this.goToCategoryComponent();
      }
    });
  }
};
</script>

<style scoped></style>
