<template>
  <div>

    <v-layout row wrap justify-end="">
      <v-btn @click="goToShopHome" icon>
        <v-icon>fas fa-store</v-icon>
      </v-btn>
    </v-layout>

    <v-layout row wrap>
      <v-flex>
        <p>
          {{ $t('shop.category.oil.text') }}
        </p>
      </v-flex>
    </v-layout>

    <v-layout row wrap>
      <card-loader :loopCount="4" v-if="loading" />
      <v-flex
        d-flex md3 class="px-2"
        v-for="(item, index) in oilProducts"
        :key="index"
      >
        <card-template
          :item="item"
          @navigateProductDetail="productDetail"
          @navigateProductEdit="editProduct"
        />
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import service from "../../../api/service";
import { errorToaster, successToaster } from "./shared/service/ErrorHandler";
import { chunkSubstr } from "../../../utils";
import CardLoader from "./shared/CardLoader";
import CardTemplate from "./shared/CardTemplate";
export default {
  name: "OilProducts",
  components: { CardLoader, CardTemplate },
  data() {
    return {
      oilProducts: [],
      loading: false
    };
  },
  methods: {
    goToShopHome() {
      this.$emit("changeComponent", ["ShopHome", ""]);
    },
    getOilsProducts() {
      this.loading = true;
      service
        .getOilsProducts()
        .then(response => {
          this.loading = false;
          const data = response.data;
          let cloneData = [];
          data.forEach(item => {
            item.productDescription =
              chunkSubstr(item.productDescription, 100)[0] + "...";
            cloneData.push(item);
          });
          this.oilProducts = cloneData;
        })
        .catch(() => {
          errorToaster(
              this.$t('products.oil.toaster.get.error.title'),
              this.$t('products.oil.toaster.get.error.description')
          );
        });
    },
    //manadatory function's called from cardTemplate
    editProduct(product) {
      this.$emit("changeComponent", ["EditProduct", product]);
    },
    productDetail(productId) {
      this.$emit("changeComponent", ["ProductDetail", productId]);
    },
    deleteProduct(productId) {
      this.loadingDetail = true;
      service
        .deleteProduct(productId)
        .then(response => {
          this.loadingDetail = false;
          successToaster(this.$t('products.oil.toaster.delete.success.title'), response.data);
        })
        .catch(error => {
          errorToaster(this.$t('products.oil.toaster.delete.error.title'), error);
        });
    }
  },
  created() {
    this.getOilsProducts();
  }
};
</script>

<style scoped></style>
