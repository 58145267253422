import Vue from "vue";
import App from "./App.vue";
import store from "./store/store";
import router from "./router";
import vuetify from "./plugins/vuetify";
import VueSimpleAlert from "vue-simple-alert";
import VueToastr2 from "vue-toastr-2";
import ZoomOnHover from "vue-zoom-on-hover";
import "vue-toastr-2/dist/vue-toastr-2.min.css";
import i18n from './i18n'

window.toastr = require("toastr");

Vue.use(VueToastr2);
Vue.use(ZoomOnHover);
Vue.use(VueSimpleAlert);
Vue.config.productionTip = false;

new Vue({
  i18n,
  store,
  router,
  vuetify,
  render: h => h(App)
}).$mount("#app");
