<template>
  <v-layout row wrap>
    <v-flex>
      <v-container>
        <v-row>
          <v-col cols="12">
            <v-card outlined>
              <v-carousel cycle show-arrows-on-hover hide-delimiters>
                <v-carousel-item
                  v-for="(item, i) in itemsCarousel"
                  :key="i"
                  :src="item.src"
                ></v-carousel-item>
              </v-carousel>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6" offset-sm="3">
            <v-card outlined>
              <v-container fluid>
                <v-row>
                  <v-col
                    v-for="n in 6"
                    :key="n"
                    class="d-flex child-flex"
                    cols="4"
                  >
                    <v-card flat tile class="d-flex">
                      <v-img
                        id="miniPoza"
                        @click="
                          zoomImage(require(`../../assets/picsum/${n}.jpg`))
                        "
                        :src="require(`../../assets/picsum/${n}.jpg`)"
                        :lazy-src="require(`../../assets/picsum/${n}.jpg`)"
                        aspect-ratio="1"
                        class="grey lighten-2"
                      >
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                          >
                            <v-progress-circular
                              indeterminate
                              color="grey lighten-5"
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-col>
        </v-row>
        <!-- Dialog for image -->
        <v-row justify="center">
          <v-dialog v-model="imageDialog" persistent max-width="500">
            <v-card>
              <v-card-title class="headline"></v-card-title>
              <v-card-text>
                <zoom-on-hover :img-normal="this.imageSrc"></zoom-on-hover>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="brown" text @click="imageDialog = false"
                  >Close</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </v-container>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  name: "Home",
  data: () => ({
    imageDialog: false,
    imageSrc: "",
    itemsCarousel: [
      {
        src: require("../../assets/carousel/acvariu.jpg")
      },
      {
        src: require("../../assets/carousel/masa.jpg")
      },
      {
        src: require("../../assets/carousel/chiuveta.jpg")
      },
      {
        src: require("../../assets/carousel/masa1.jpg")
      },
      {
        src: require("../../assets/carousel/semineu.jpg")
      }
    ]
  }),
  methods: {
    zoomImage(src) {
      this.imageSrc = src;
      this.imageDialog = true;
    }
  },
  created() {
    window.addEventListener("keydown", e => {
      if (e.key == "Escape") {
        this.imageDialog = false;
      }
    });
  }
};
</script>

<style scoped>
#miniPoza:hover {
  cursor: pointer;
}
</style>
