<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="4">
        <v-card class="elevation-12">
          <v-toolbar color="brown" dark flat>
            <v-toolbar-title>{{ $t('login.title') }}</v-toolbar-title>
            <v-spacer />
            <v-tooltip right>
              <template v-slot:activator="{ on }">
                <v-btn icon large @click="goHome" target="_blank" v-on="on">
                  <v-icon>fas fa-home</v-icon>
                </v-btn>
              </template>
              <span>{{ $t('header.home') }}</span>
            </v-tooltip>
          </v-toolbar>
          <v-card-text>
            <createAccount v-if="isSignUp"></createAccount>
            <v-form
              class="form-signin"
              @submit.prevent="login"
              v-if="!isSignUp"
            >
              <img
                class="mb-4"
                src="../../assets/login-img.png"
                alt
                width="72"
                height="72"
              />
              <h1 class="h3 mb-3 font-weight-normal">{{ $t('login.subtitle') }}</h1>
              <v-text-field
                class="form-control"
                id="email"
                :rules="emailRules"
                :label="$t('login.email')"
                v-model="email"
                required
                autocomplete="email"
              />
              <v-text-field
                append-outer-icon="fas fa-eye"
                @click:append-outer="showPassword"
                :type="passwordFieldType"
                class="form-control"
                id="password"
                :label="$t('login.password')"
                v-model="password"
                required
                autocomplete="current-password"
              />
              <p class="text-muted">
                <a href="javascript:;;">{{ $t('login.forget') }}</a>
              </p>
              <v-btn class="white--text" color="brown" type="submit" :disabled="!isFormInValid">
                <i class="fa fa-spinner fa-spin mr-1" v-if="showLoader"></i>{{ $t('header.login') }}
              </v-btn>
            </v-form>
          </v-card-text>
          <v-card-text>
            <p class="mt-3 text-muted">
              <span v-if="isSignUp">
                {{ $t('login.already') }}
                <a href="javascript:;;" @click="toggleForm">{{ $t('login.connect') }}</a>
              </span>
              <span v-if="!isSignUp">
                {{ $t('login.new') }}
                <a href="javascript:;;" @click="toggleForm"
                  >{{ $t('login.create') }}</a
                >
              </span>
            </p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import CreateAccount from "../shop/products/CreateAccount";
import service from "@/api/service";
import { errorToaster } from "../shop/products/shared/service/ErrorHandler";

import { mapMutations } from "vuex";

export default {
  name: "Login",
  components: { CreateAccount },
  data() {
    return {
      passwordFieldType: 'password',
      email: "",
      password: "",
      showLoader: false,
      isSignUp: false,
      emailRules: [
        v => !!v || this.$t('register.rules.emailRequired'),
        v => /.+@.+\..+/.test(v) || this.$t('register.rules.emailValid')
      ],
    };
  },
  computed: {
    isFormInValid() {
      return /.+@.+\..+/.test(this.email);
    },
  },
  methods: {
    toggleForm() {
      this.isSignUp = !this.isSignUp;
    },

    ...mapMutations(["ADD_LOGGED_USER"]),

    //  Login function using email and password
    login(event) {
      this.showLoader = true;
      const user = {
        email: this.email,
        password: this.password
      };

      service.login(user).then(response => {
          this.showLoader = false;
          this.ADD_LOGGED_USER(response.data[0]);
          event.target.reset();
          this.$router.push(this.$route.query.from || "/");
        })
        .catch(error => {
          this.showLoader = false;
          errorToaster(this.$t('login.toaster.error.title'), error);
        });
    },
    goHome() {
      this.$router.push(this.$route.query.from || "/");
    },
    showPassword(){
      this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password'
    },
  }
};
</script>
<style>
.form-signin {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: auto;
}

.form-signin .checkbox {
  font-weight: 400;
}
.form-signin .form-control {
  position: relative;
  box-sizing: border-box;
  height: auto;
  padding: 10px;
  font-size: 16px;
}
.form-signin .form-control:focus {
  z-index: 2;
}
.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
</style>
