<template>
  <div class="create-account">
    <form id="idForm" class="form-signup" @submit.prevent="createAccount">
      <img
        class="mb-4"
        src="../../../assets/create-account.svg"
        alt
        width="72"
        height="72"
      />
      <h1 class="h3 mb-3 font-weight-normal">{{ $t('login.create') }}</h1>

      <div class="row">
        <div class="col-md-6 mb-3">
          <v-text-field
            class="form-control"
            :rules="nameRules"
            id="firstName"
            :label="$t('register.firstName')"
            v-model="user.firstName"
            required
          />
        </div>
        <div class="col-md-6 mb-3">
          <v-text-field
            class="form-control"
            :rules="nameRules"
            id="lastName"
            :label="$t('register.lastName')"
            v-model="user.lastName"
            required
          />
        </div>
      </div>
      <div class="row">
        <div class="col-md mb-3">
          <v-text-field
            class="form-control"
            :rules="emailRules"
            id="emailId"
            :label="$t('register.email')"
            v-model="user.email"
            required
          />
        </div>
      </div>
      <div class="row">
        <div class="col-md mb-3">
          <v-text-field
            append-outer-icon="fas fa-eye"
            @click:append-outer="showPassword"
            :type="passwordFieldType"
            class="form-control"
            :rules="pswRules"
            id="act-password"
            :label="$t('register.newPassword')"
            v-model="user.password"
            required
          />
        </div>
      </div>
      <v-btn class="white--text" :disabled="!isFormInValid" color="#458ccc" type="submit">
        <i class="fa fa-spinner fa-spin mr-1 " v-if="showLoader"></i>{{ $t('register.signUp') }}
      </v-btn>
    </form>
  </div>
</template>
<script>
import service from "../../../api/service";
import { successToaster, errorToaster } from "./shared/service/ErrorHandler.js";
export default {
  name: "CreateAccount",
  data() {
    return {
      showLoader: false,
      passwordFieldType: 'password',
      user: {
        firstName: "",
        lastName: "",
        email: "",
        password: ""
      },
      emailRules: [
        v => !!v || this.$t('register.rules.emailRequired'),
        v => /.+@.+\..+/.test(v) || this.$t('register.rules.emailValid')
      ],
      nameRules: [
        v => !!v || this.$t('register.rules.nameRequired'),
        v => (v && v.length >= 5) || this.$t('register.rules.nameMin5Char')
      ],
      pswRules: [
          v => !!v || this.$t('register.rules.passwordRequired'),
          v => /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/.test(v) || this.$t('register.rules.min8Char1Let1Num1Special')
      ]
    };
  },
  computed: {
    isFormInValid() {
      return this.user.firstName.length >= 5 &&
             this.user.lastName.length >= 5 &&
             /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/.test(this.user.password) &&
             /.+@.+\..+/.test(this.user.email);
    },
  },
  methods: {
    createAccount(event) {
      event.preventDefault();

      this.showLoader = true;

        service
          .createAccount(this.user)
          .then(() => {
            this.emptyForm();
            this.showLoader = false;
            successToaster(
                this.$t('register.toaster.success.title'),
                this.$t('register.toaster.success.description')
            );
          })
          .catch(() => {
            this.emptyForm();
            errorToaster(
                this.$t('register.toaster.error.title'),
                this.$t('register.toaster.error.description')
            );
          });
      },
    showPassword(){
      this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password'
    },
    emptyForm(){
      this.user.firstName = "";
      this.user.lastName = "";
      this.user.email = "";
      this.user.password = "";
    }
  }
};
</script>

<style>
.form-signup {
  width: 100%;
  max-width: 500px;
  padding: 15px;
  margin: auto;
}
</style>
