import Vue from "vue";
import Vuex from "vuex";
import { encryptUser } from "../components/shop/products/shared/service/authService";

Vue.use(Vuex);

export const getDefaultState = () => {
  return {
    cartProducts: [],
    loggedUser: {},
    breadcrumbComponents: "",
    componentKey: 0,
    categoryComponent: ""
  };
};

export default new Vuex.Store({
  state: getDefaultState(),

  mutations: {
    ADD_CART_LOCAL: (state, product) => {
      state.cartProducts.push(product);
      localStorage.setItem("iki-cart", JSON.stringify(state.cartProducts));
    },

    ADD_LOGGED_USER: (state, user) => {
      state.loggedUser = user;
      localStorage.setItem("_auth", encryptUser(user));
    },

    SET_CART_PRODUCTS: (state, products) => {
      state.cartProducts = [];
      state.cartProducts = products;
    },
    resetState: state => {
      const obj = JSON.parse(localStorage.getItem("store"));
      Object.assign(state, obj);
    },
    setDefaultState: state => {
      Object.assign(state, getDefaultState());
    },
    setBreadCrumbComponents: (state, breadCrumbComponent) => {
      state.breadcrumbComponents += breadCrumbComponent;
    },
    setCategoryComponent: (state, categoryComponent) => {
      state.categoryComponent = categoryComponent;
    },
    forceRerender: state => {
      state.componentKey += 1;
    },
    removeLastBreadCrumbComponent: state => {
      const array = state.breadcrumbComponents.split("/");
      let removedBreadCrumb = "";
      array.forEach((el, index) => {
        if (index != array.length - 1) {
          removedBreadCrumb += el + "/";
        }
      });
      removedBreadCrumb = removedBreadCrumb.substring(
        0,
        removedBreadCrumb.length - 1
      );
      state.breadcrumbComponents = removedBreadCrumb;
    }
  },

  getters: {
    cartProducts: state => {
      return state.cartProducts;
    },
    getLoggedUser: state => {
      return state.loggedUser;
    },
    getBreadCrumbComponents: state => {
      return state.breadcrumbComponents;
    },
    getComponentKey: state => {
      return state.componentKey;
    },
    getCategoryComponent: state => {
      return state.categoryComponent;
    }
  },

  actions: {},

  modules: {}
});
