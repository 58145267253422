<template>
  <v-layout row wrap>
    <v-flex>
      <v-card outlined>
        <v-img :src="this.src" max-height="60px" :aspect-ratio="ratio">
          <v-layout row wrap>
            <v-flex xs12 sm12 md12 lg12 xl12>
              <ul class="d-none d-lg-block">
                <li><a><img style="cursor: pointer;" width="50%" src="https://www.countryflags.io/gb/shiny/64.png" @click="$i18n.locale='en'"></a></li>
                <li><a><img style="cursor: pointer;" width="50%" src="https://www.countryflags.io/ro/shiny/64.png" @click="$i18n.locale='ro'"></a></li>
                <li><router-link to="/login">{{ $t('header.login') }}</router-link></li>
                <li><a @click="changeComponent('About')">{{ $t('header.about') }}</a></li>
                <li><a @click="changeComponent('Cart')">{{ $t('header.cart') }}</a></li>
                <li><a @click="changeComponent('ShopHome')">{{ $t('header.shop') }}</a></li>
                <li v-if="loggedUser.isAdmin">
                  <a @click="changeComponent('AdminPage')"
                    >{{ $t('header.admin') }}</a
                  >
                </li>
                <li><a @click="changeComponent('Home')">{{ $t('header.home') }}</a></li>
              </ul>
              <v-toolbar
                class="d-lg-none"
                style="float: right"
                collapse
                flat
                color="transparent"
              >
                <v-menu offset-y>
                  <template v-slot:activator="{ on }">
                    <v-btn icon v-on="on">
                      <v-icon>fas fa-align-justify</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item>
                      <v-list-item-title><img style="cursor: pointer;" width="50%" src="https://www.countryflags.io/gb/shiny/64.png" @click="$i18n.locale='en'"></v-list-item-title>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title><img style="cursor: pointer;" width="50%" src="https://www.countryflags.io/ro/shiny/64.png" @click="$i18n.locale='ro'"></v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="changeComponent('Home')">
                      <v-list-item-title>{{ $t('header.home') }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item v-if="loggedUser.isAdmin" @click="changeComponent('AdminPage')">
                      <v-list-item-title>{{ $t('header.admin') }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="changeComponent('ShopHome')">
                      <v-list-item-title>{{ $t('header.shop') }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="changeComponent('Cart')">
                      <v-list-item-title>{{ $t('header.cart') }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="changeComponent('About')">
                      <v-list-item-title>{{ $t('header.about') }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item router-link to="/login">
                      <v-list-item-title>{{ $t('header.login') }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-toolbar>
            </v-flex>
          </v-layout>
        </v-img>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "Header",
  data: () => ({
    ratio: 10,
    src: require("../../assets/banner3.png")
  }),
  computed: mapState(["loggedUser"]),
  methods: {
    changeComponent(component) {
      this.$emit("changeComponent", [component, ""]);
    }
  }
};
</script>

<style scoped>
ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
}
li {
  float: right;
}
li a {
  display: block;
  color: black;
  text-align: center;
  padding: 16px;
  text-decoration: none;
}
li a:hover {
  color: grey;
}
</style>
