<template>
  <v-container>
    <v-layout row wrap class="justify-center">
      <v-flex xs11 sm11 md12 lg12 xl12>
        <Header @changeComponent="changeComponent" />
      </v-flex>
    </v-layout>

    <v-layout row wrap class="justify-center">
      <v-flex xs11 sm11 md12 lg12 xl12 style="padding: 2%">
          <v-card outlined>
            <component
              :is="currentComponent"
              :obj="obj"
              @changeComponent="changeComponent"
              :key="forzeRender"
              style="padding: 4%"
            ></component>
          </v-card>
      </v-flex>
    </v-layout>

    <v-layout row wrap class="justify-center">
      <v-flex xs11 sm11 md12 lg12 xl12>
        <Footer />
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import Header from "../header/Header";
import Footer from "../footer/Footer";
import About from "../body/About";
import Home from "../body/Home";
import ShopHome from "../shop/ShopHome";
import AdminPage from "@/components/shop/products/actions/AdminPage";
import CreateProductAdmin from "../shop/products/actions/CreateProductAdmin";
import HandlePicsumHomePage from "@/components/shop/products/actions/HandlePicsumHomePage";
import ProductDetail from "../shop/products/ProductDetail";
import EditProduct from "../shop/products/actions/EditProduct";
import Cart from "../shop/products/cart/CartProducts";
import OilProducts from "../shop/products/OilProducts";
import ChopperProducts from "../shop/products/ChopperProducts";
import TableProducts from "@/components/shop/products/TableProducts";
import Login from "../body/Login";
import { mapMutations } from "vuex";

export default {
  name: "Layout",
  components: {
    Home,
    ShopHome,
    AdminPage,
    CreateProductAdmin,
    HandlePicsumHomePage,
    Cart,
    OilProducts,
    ChopperProducts,
    TableProducts,
    ProductDetail,
    EditProduct,
    Login,
    About,
    Footer,
    Header
  },
  computed: {
    getComponentKey() {
      return this.$store.getters.getComponentKey;
    }
  },
  data() {
    return {
      currentComponent: "Home",
      obj: {},
      forzeRender: 1
    };
  },
  methods: {
    ...mapMutations(["setBreadCrumbComponents"]),
    changeComponent(args) {
      const component = args[0];

      if (component == "EditProduct") {
        this.obj = args[1];
      } else {
        this.obj.id = args[1];
        this.setBreadCrumbComponents("/" + this.currentComponent);
      }
      this.forzeRender = this.getComponentKey;
      this.currentComponent = component;
    }
  }
};
</script>

<style scoped></style>
