export const blobToBase64 = blob => {
  const reader = new FileReader();
  reader.readAsDataURL(blob);
  return new Promise(resolve => {
    reader.onloadend = () => {
      resolve(reader.result);
    };
  });
};

export const chunkSubstr = (str, size) => {
  const numChunks = Math.ceil(str.length / size);
  const chunks = new Array(numChunks);
  let offset = 0;

  for (let i = 0; i < size; i++) {
    chunks[i] = str.substr(offset, size);
    offset += size;
  }

  return chunks;
};
