<template>
  <v-layout row wrap>
    <v-flex d-flex>
      <v-card class="mx-auto d-flex flex-column" max-width="250">
        <v-img :src="item.productImage" max-height="180"></v-img>

        <v-card-title>
          {{ item.productName }}
        </v-card-title>

        <v-card-subtitle> {{ $t('cart.price') }} {{ item.productPrice }} {{ $t('cart.currency') }} </v-card-subtitle>

        <v-card-text>
          {{ item.productDescription }}
        </v-card-text>

        <v-spacer></v-spacer>

        <v-card-actions>
          <v-btn @click="navigateProductDetail(item)" icon>
            <v-icon>fas fa-eye</v-icon>
          </v-btn>

          <v-btn
            @click="updateEditProduct(item)"
            v-if="loggedUser && loggedUser.isAdmin"
            icon
          >
            <v-icon>fas fa-edit</v-icon>
          </v-btn>

          <v-spacer></v-spacer>

          <v-btn
            v-if="loggedUser && loggedUser.isAdmin"
            @click="deleteProduct(item)"
            icon
          >
            <v-icon>fas fa-trash-alt</v-icon>
          </v-btn>

          <v-btn @click="addToCart(item)" icon>
            <v-icon>fas fa-shopping-cart</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import { infoToaster, successToaster } from "./service/ErrorHandler";
import _ from "lodash";
export default {
  name: "CardTemplate",
  props: ["item"],
  data() {
    return {
      showModal: false
    };
  },
  computed: mapState(["loggedUser"]),
  methods: {
    ...mapMutations(["ADD_CART_LOCAL"]),
    addToCart(product) {
      const data = _.find(this.$store.getters.cartProducts, product);
      if (data) {
        infoToaster(this.$t('cart.toaster.info.title'), this.$t('cart.toaster.info.description'));
      } else {
        successToaster(this.$t('cart.toaster.success.title'), this.$t('cart.toaster.success.description'));
        this.ADD_CART_LOCAL(product);
      }
    },
    // this will trigger the parentComponent function
    updateEditProduct(product) {
      //this.$parent.editProduct(product, productId);
      this.$emit("navigateProductEdit", product);
    },
    navigateProductDetail(product) {
      this.$emit("navigateProductDetail", product._id);
      // console.log(this)
      // this.$parent.productDetail(product._id);
    },
    deleteProduct(product) {
      this.$parent.deleteProduct(product._id);
    }
  }
};
</script>

<style scoped></style>
