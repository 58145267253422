import axios from "axios";

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  timeout: 100000
});

export default {
  get(url) {
    return instance.get(url);
  },

  post(url, request) {
    return instance.post(url, request);
  },

  put(url, request) {
    return instance.put(url, request);
  },

  delete(url, request) {
    return instance.delete(url, request);
  }
};
