<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="600px">
      <template v-slot:activator="{ on }">
        <v-btn color="primary" dark v-on="on">{{ $t('checkout.title') }}</v-btn>
      </template>
      <v-card>
        <v-card-title>
          <span class="headline">{{ $t('checkout.title') }}</span>
        </v-card-title>
        <v-card-text>
          <v-container grid-list-lg>
            <form id="productCU" @submit="actionProduct">
              <v-layout row wrap>
                <v-flex>
                  <v-text-field
                    id="nameSurname"
                    v-model="shippingDetail.nameSurname"
                    :label="$t('checkout.shippingDetail.nameSurname')"
                    class="form-control"
                  />
                </v-flex>
              </v-layout>
              <v-layout row wrap>
                <v-flex>
                  <v-text-field
                    class="form-control"
                    id="telephone"
                    name="telephone"
                    v-model="shippingDetail.telephone"
                    :label="$t('checkout.shippingDetail.telephone')"
                  />
                </v-flex>
              </v-layout>
              <v-layout row wrap>
                <v-flex>
                  <v-text-field
                    class="form-control"
                    id="emaik"
                    name="email"
                    v-model="shippingDetail.email"
                    :label="$t('checkout.shippingDetail.email')"
                  />
                </v-flex>
              </v-layout>
              <v-layout row wrap>
                <v-flex>
                  <v-switch
                    @change="setIsJuridic"
                    :label="$t('checkout.shippingDetail.legal')"
                  ></v-switch>
                </v-flex>
              </v-layout>
              <div v-if="!isJuridic">
                <v-layout row wrap>
                  <v-flex>
                    <v-text-field
                      class="form-control"
                      id="adress"
                      name="address"
                      v-model="shippingDetail.address"
                      :label="$t('checkout.shippingDetail.address')"
                    />
                  </v-flex>
                </v-layout>
                <v-layout row wrap>
                  <v-flex>
                    <v-select
                      :items="itemsCounties"
                      @input="setCounties"
                      :label="$t('checkout.shippingDetail.counties')"
                      dense
                    ></v-select>
                  </v-flex>
                </v-layout>
                <v-layout row wrap v-if="isCounties">
                  <v-flex>
                    <v-select
                      :items="itemsSettlement"
                      v-model="shippingDetail.settlement"
                      :label="$t('checkout.shippingDetail.settlement')"
                      dense
                    ></v-select>
                  </v-flex>
                </v-layout>
              </div>
              <div v-else>
                <v-layout row wrap>
                  <v-flex>
                    <v-text-field
                      class="form-control"
                      id="companyName"
                      name="companyName"
                      v-model="shippingDetail.company.companyName"
                      :label="$t('checkout.shippingDetail.company.companyName')"
                    />
                  </v-flex>
                </v-layout>
                <v-layout row wrap>
                  <v-flex>
                    <v-text-field
                      class="form-control"
                      id="atribut"
                      name="atribut"
                      v-model="shippingDetail.company.atribut"
                      :label="$t('checkout.shippingDetail.company.atribut')"
                    />
                  </v-flex>
                </v-layout>
                <v-layout row wrap>
                  <v-flex>
                    <v-text-field
                      class="form-control"
                      id="inregistrare"
                      name="inregistrare"
                      v-model="shippingDetail.company.inregistrare"
                      :label="$t('checkout.shippingDetail.company.registrationCode')"
                    />
                  </v-flex>
                </v-layout>
                <v-layout row wrap>
                  <v-flex>
                    <v-text-field
                      class="form-control"
                      id="iban"
                      name="iban"
                      v-model="shippingDetail.company.iban"
                      :label="$t('checkout.shippingDetail.company.iban')"
                    />
                  </v-flex>
                </v-layout>
                <v-layout row wrap>
                  <v-flex>
                    <v-text-field
                      class="form-control"
                      id="bank"
                      name="bank"
                      v-model="shippingDetail.company.bank"
                      :label="$t('checkout.shippingDetail.company.bank')"
                    />
                  </v-flex>
                </v-layout>
                <v-layout row wrap>
                  <v-flex>
                    <h2>{{ $t('checkout.shippingDetail.registeredOffice') }}</h2>
                  </v-flex>
                </v-layout>
                <v-layout row wrap>
                  <v-flex>
                    <v-text-field
                      class="form-control"
                      id="adress"
                      name="address"
                      v-model="shippingDetail.address"
                      :label="$t('checkout.shippingDetail.address')"
                    />
                  </v-flex>
                </v-layout>
                <v-layout row wrap>
                  <v-flex>
                    <v-select
                      :items="itemsCounties"
                      @input="setCounties"
                      :label="$t('checkout.shippingDetail.counties')"
                      dense
                    ></v-select>
                  </v-flex>
                </v-layout>
                <v-layout row wrap v-if="isCounties">
                  <v-flex>
                    <v-select
                      :items="itemsSettlement"
                      v-model="shippingDetail.settlement"
                      :label="$t('checkout.shippingDetail.settlement')"
                      dense
                    ></v-select>
                  </v-flex>
                </v-layout>
              </div>
              <br />
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDialog"
                  >{{ $t('button.close') }}</v-btn
                >
                <v-btn
                  color="blue darken-1"
                  text
                  :disabled="isDisabled"
                  type="submit"
                  >{{ $t('button.save') }}</v-btn
                >
              </v-card-actions>
            </form>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import service from "../../../../api/service";
import { errorToaster, successToaster } from "../shared/service/ErrorHandler";
import { mapState } from "vuex";
import emailjs from "emailjs-com";
const judete = require("../../../../../judete");

export default {
  name: "CheckoutModal",
  data() {
    return {
      dialog: false,
      isJuridic: false,
      itemsCounties: [],
      isCounties: false,
      itemsSettlement: [],
      shippingDetail: new Object()
    };
  },
  computed: {
    ...mapState(["cartProducts"]),
    isDisabled: function() {
      return !(
        this.shippingDetail.nameSurname &&
        this.shippingDetail.telephone &&
        this.shippingDetail.email &&
        this.shippingDetail.address &&
        this.shippingDetail.counties &&
        this.shippingDetail.settlement &&
        this.isDisabledJuridic()
      );
    }
  },
  methods: {
    isDisabledJuridic() {
      if (this.isJuridic) {
        return (
          this.shippingDetail.company.companyName &&
          this.shippingDetail.company.bank &&
          this.shippingDetail.company.atribut &&
          this.shippingDetail.company.inregistrare &&
          this.shippingDetail.company.iban
        );
      } else {
        return true;
      }
    },
    setIsJuridic(item) {
      this.shippingDetail = new Object();
      if (item) {
        this.shippingDetail.company = new Object();
      }
      this.isJuridic = item;
    },
    setCounties(item) {
      this.itemsSettlement = [];
      judete.judete.forEach(el => {
        if (item == el.nume) {
          el.localitati.forEach(localitie => {
            this.itemsSettlement.push(localitie.nume);
          });
        }
      });

      this.shippingDetail.counties = item;
      this.isCounties = true;
    },
    actionProduct(event) {
      event.preventDefault();

      this.shippingDetail.products = JSON.stringify(this.forTheFuckingEmail());
      emailjs
        .send(
          "default_service",
          "doitamplari",
          this.shippingDetail,
          "user_gEpyH0TmPYTncgeNnxzwZ"
        ) //use your Service ID and Template ID
        .then(() => {
          this.closeDialog();

          this.setCheckoutProducts();
        })
        .catch(() => {});
    },
    setCheckoutProducts() {
      service
        .setCheckoutProducts(this.normalizeObject())
        .then(() => {
          successToaster(
              this.$t('checkout.toaster.success.title'),
              this.$t('checkout.toaster.success.description')
          );
        })
        .catch(() => {
          errorToaster(
              this.$t('checkout.toaster.error.title'),
              this.$t('checkout.toaster.error.description')
          );
        });
    },
    closeDialog() {
      this.dialog = false;
      this.isJuridic = false;
      this.shippingDetail = new Object();
    },
    normalizeObject() {
      let obj1 = [];
      this.cartProducts.forEach(product => {
        let obj = {};
        obj["productName"] = product.productName;
        obj["productPrice"] = product.productPrice;
        obj["pieces"] = product.number;
        obj1.push(obj);
      });
      return obj1;
    },
    forTheFuckingEmail() {
      let obj = {};
      this.normalizeObject().forEach((product, index) => {
        obj[index] = product;
      });

      if (this.isJuridic) {
        obj["companyName"] = this.shippingDetail.company.companyName;
        obj["atribut"] = this.shippingDetail.company.atribut;
        obj["inregistrare"] = this.shippingDetail.company.inregistrare;
        obj["iban"] = this.shippingDetail.company.iban;
        obj["bank"] = this.shippingDetail.company.bank;

        obj["address"] = this.shippingDetail.address;
        obj["counties"] = this.shippingDetail.counties;
        obj["settlement"] = this.shippingDetail.settlement;
      } else {
        obj["address"] = this.shippingDetail.address;
        obj["counties"] = this.shippingDetail.counties;
        obj["settlement"] = this.shippingDetail.settlement;
      }

      return obj;
    }
  },
  created() {
    judete.judete.forEach(el => {
      this.itemsCounties.push(el.nume);
    });
  }
};
</script>

<style scoped></style>
