<template>
  <v-layout row wrap >
    <v-flex d-flex md3 class="px-2">
      <v-card class="mx-auto d-flex flex-column" max-width="250">
        <v-img @click="goToChopperProducts"
          :src="require('../../assets/tocatoare/category_tocator.jpg')"
          height="auto" class="hand"
        ></v-img>

        <v-card-title @click="goToChopperProducts" class="hand">
          {{ $t('shop.category.chopper.title') }}
        </v-card-title>

        <v-card-subtitle> {{ $t('shop.category.chopper.subtitle') }} </v-card-subtitle>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn icon @click="goToChopperProducts">
            <v-icon>fas fa-search</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-flex>

    <v-flex d-flex md3 class="px-2">
      <v-card class="mx-auto d-flex flex-column" max-width="250">
        <v-img @click="goToOilProducts" class="hand"
          :src="require('../../assets/ulei/category_ulei.jpg')"
          height="auto"
        ></v-img>

        <v-card-title @click="goToOilProducts" class="hand">
          {{ $t('shop.category.oil.title') }}
        </v-card-title>

        <v-card-subtitle> {{ $t('shop.category.oil.subtitle') }} </v-card-subtitle>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn icon @click="goToOilProducts">
            <v-icon>fas fa-search</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-flex>

    <v-flex d-flex md3 class="px-2">
      <v-card class="mx-auto d-flex flex-column" max-width="250">
        <v-img @click="goToTableProducts" class="hand"
          :src="require('../../assets/mese/category_mese.jpg')"
          height="210"
        ></v-img>

        <v-card-title @click="goToTableProducts" class="hand">
          {{ $t('shop.category.table.title') }}
        </v-card-title>

        <v-card-subtitle>{{ $t('shop.category.table.subtitle') }}</v-card-subtitle>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn icon @click="goToTableProducts">
            <v-icon>fas fa-search</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import { mapMutations } from "vuex";

export default {
  name: "ShopHome",
  components: {},
  methods: {
    ...mapMutations(["setCategoryComponent"]),
    goToOilProducts() {
      this.setCategoryComponent("OilProducts");
      this.$emit("changeComponent", ["OilProducts", ""]);
    },
    goToChopperProducts() {
      this.setCategoryComponent("ChopperProducts");
      this.$emit("changeComponent", ["ChopperProducts", ""]);
    },
    goToTableProducts() {
      this.setCategoryComponent("TableProducts");
      this.$emit("changeComponent", ["TableProducts", ""]);
    }
  }
};
</script>

<style scoped>
.hand{
  cursor: pointer;
}
</style>
