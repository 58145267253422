<template>
  <v-layout row wrap>
    <v-flex>
      <v-layout row wrap>
        <v-flex>
          <v-card raised class="mx-auto" max-width="400">
            <v-img height="100%" src="../../assets/portrait-background.jpg">
              <v-row align="end" class="fill-height">
                <v-col align-self="start" class="pa-0" cols="12">
                  <v-avatar class="profile" color="grey" size="164" tile>
                    <v-img src="../../assets/portrait.jpg"></v-img>
                  </v-avatar>
                </v-col>
                <v-col class="py-0">
                  <v-list-item color="rgba(0, 0, 0, .4)" dark>
                    <v-list-item-content>
                      <v-list-item-title class="title"
                        >Robert</v-list-item-title
                      >
                      <v-list-item-subtitle>{{ $t('about.title') }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
            </v-img>
          </v-card>
        </v-flex>
      </v-layout>
      <v-layout row wrap class="justify-center">
        <v-flex>
          <v-card-text>
            <p class="display-1 text--primary text-center">
              {{ $t('about.title') }}
            </p>
          </v-card-text>
          <v-card-text>
            <div class="text--primary text-left">
              {{ $t('about.description') }}
            </div>
          </v-card-text>
          <v-card-text>
            <div class="text--primary text-left">
              {{ $t('about.contact') }}
            </div>
          </v-card-text>
        </v-flex>
      </v-layout>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  name: "About"
};
</script>

<style scoped></style>
